'use strict';

import $ from 'jquery';

$(() => {
  //$('.img').hide();
  //$('#img1').css('background-image','url("images/3.jpg")');


  for(let i=1;i<=6;i++){
    $('#img1').addClass('visible');
    $('#img'+i).css('background-image','url("images/'+i+'.jpg")');
  }
  let img_i = 0;
  setInterval(()=>{
    if( img_i < 6){
      img_i++;
    }else{
      img_i = 1;
    }
    fadeImages(img_i);
  },2000);
  
  let fadeImages = (i)=>{ 
    $('.img').removeClass('visible');
    $('#img'+i).addClass('visible');
  }
  
  let isYellow = false;
  $('.trigger').click((e)=>{
    if(isYellow){
      $('#events').removeClass('open');
      $('#navbar').removeClass('yellow');
      $('.menu-icon').toggleClass('active');
      isYellow = false;
    }else{
      $('.menu-icon').toggleClass('active');
      $('#staff').toggleClass('open');      
    }
  });
  $('#event_link').click((e)=>{
    e.preventDefault();
    $('#staff').removeClass('open');
    $('#events').addClass('open');
    $('#navbar').addClass('yellow');
    isYellow = true;
  });
});